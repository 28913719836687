import React, { Suspense } from 'react';
import Box from '@mui/material/Box';
import { useDialog } from '@components/hooks/useDialog';
import { CreateRoleForm } from '@components/Roles/CreateRoleForm';
import AddIcon from '@mui/icons-material/Add';

const CreateRoleButton = ({ id }: { id: string }) => {
  const { isOpen, handleOpen, handleClose } = useDialog();
  return (
    <Suspense fallback={null}>
      <Box className="flex   ">
        <Box
          onClick={handleOpen}
          display="flex"
          className="justify-end"
          gap={1}
          sx={{ cursor: 'pointer' }}
        >
          <AddIcon sx={{ width: '20px', cursor: 'pointer' }} color="success" />
        </Box>
        <CreateRoleForm isOpen={isOpen} handleClose={handleClose} id={id} />
      </Box>
    </Suspense>
  );
};

export default CreateRoleButton;
