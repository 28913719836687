import { useListRoles } from '@services/queries/IAMQueries';
import { ListRolesRequest } from '@proto/iam/auth/v1/iam_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import Box from '@mui/material/Box';
import CreateRoleButton from '@components/Roles/CreateRoleButton';
import React from 'react';
import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

export const SystemRolesLabels = {
  SYSTEM_ROLE_ADMIN: 'Admin',
  SYSTEM_ROLE_MEMBER: 'Member',
  SYSTEM_ROLE_OWNER: 'Owner',
  SYSTEM_ROLE_INTERVIEWER: 'Interviewer',
};

const ListRoles = ({ domainId, toe }: { domainId: string; toe: Entity_Type }) => {
  const navigate = useNavigate();
  const { data: listRoles } = useListRoles(
    new ListRolesRequest({
      domainId: new UUID({ value: domainId }),
    })
  );

  return (
    <Stack gap={0.5}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
          Roles
        </Typography>

        <CreateRoleButton id={domainId} />
      </Stack>

      <List>
        {listRoles?.roles?.map((role) => (
          <Box sx={{ cursor: 'pointer' }} key={role.roleId?.value}>
            <ListItem
              onClick={() => {
                navigate(`/manage-role/${domainId}/${toe}/${role?.roleId?.value}`);
              }}
              disablePadding
            >
              <ListItemButton>
                <ListItemText
                  primary={`${
                    SystemRolesLabels[role.name as keyof typeof SystemRolesLabels] || role.name
                  }`}
                />
              </ListItemButton>
            </ListItem>
          </Box>
        ))}
      </List>
    </Stack>
  );
};

export default ListRoles;
