import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SystemRolesLabels } from '@components/Roles/ListRoles';
import React from 'react';
import { useDeleteRole, useGetRole } from '@services/queries/IAMQueries';
import { DeleteRoleRequest } from '@proto/iam/auth/v1/iam_pb';
import DeleteIcon from '@mui/icons-material/Delete';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import RolesAutocomplete from '@components/Roles/RolesAutocomplete';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { a11yProps, TabPanel } from '@components/TabPanel/TabPanel';
import ManagePolicies from '@components/Roles/ManagePolicies';

export const toePolicyLabel = {
  [Entity_Type.USER]: 'User',
  [Entity_Type.INTERVIEW]: 'Interview',
  [Entity_Type.DEMAND]: 'Demand',
  [Entity_Type.CONTRACT]: 'Contract',
  [Entity_Type.PRODUCT]: 'Product',
  [Entity_Type.EVALUATION]: 'Evaluation',
  [Entity_Type.ORG]: 'Organization',
  [Entity_Type.DASHBOARD]: 'Dashboard',
  [Entity_Type.SQUAD]: 'Squad',
  [Entity_Type.DYNAMIC_SQUAD]: 'Dynamic squad',
  [Entity_Type.ORDER]: 'Order',
  [Entity_Type.SUPPLY]: 'Supply',
  [Entity_Type.EVALUATION_FEEDBACK]: 'Evaluation feedback',
  [Entity_Type.INVITE]: 'Invite',
  [Entity_Type.IAM_ROLE]: 'IAM Role',
  [Entity_Type.IAM_POLICY]: 'IAM Policy',
  [Entity_Type.TREASURY_INVOICE]: 'Treasury invoice',
  [Entity_Type.TREASURY_WALLET]: 'Treasury wallet',
  [Entity_Type.EVALUATION_SLOT]: 'Evaluation slot',
  [Entity_Type.CHAT_GROUP]: 'Chat group',
  [Entity_Type.STEP]: 'Steps',
  // [Entity_Type.MEMBER]: 'Member',
  [Entity_Type.AVATAR]: 'Avatar',
};

const urlEntityType = {
  [Entity_Type.SQUAD]: 'squads',
  [Entity_Type.ORG]: 'orgs',
};

const ManageRole = () => {
  const navigation = useNavigate();
  const { roleId, domainId, toe } = useParams();
  const roleUUID = new UUID({ value: roleId });
  const { data: getRole } = useGetRole(roleUUID);
  const { mutate: deleteRole } = useDeleteRole();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get('tab') || 'policies';
  const tabMapping = ['policies', 'users', 'danger-zone'];

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSearchParams({ tab: tabMapping[newValue] });
  };

  const getCurrentTabIndex = (): number => {
    return tabMapping.indexOf(tabParam);
  };

  return (
    <Page title="Manage Role" description="Manage role">
      <Container>
        <Box>
          <Typography variant="subtitle1" mb={2} textAlign="center">
            Manage role{' '}
            <span style={{ fontWeight: 800 }}>{`${
              SystemRolesLabels[getRole?.name as keyof typeof SystemRolesLabels] || getRole?.name
            }`}</span>
          </Typography>
        </Box>
        <Box className="flex">
          <Tabs
            orientation="vertical"
            className="w-[12%]"
            value={getCurrentTabIndex()}
            onChange={handleTabChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab className="!items-start !pr-6" label="Policies" {...a11yProps(0)} />
            <Tab className="!items-start !pr-6" label="Users" {...a11yProps(1)} />
            <Tab className="!items-start !pr-6" label="Danger zone" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={getCurrentTabIndex()} index={0}>
            <ManagePolicies />
          </TabPanel>

          <TabPanel value={getCurrentTabIndex()} index={1}>
            <Box mb={2}>
              {roleUUID && <RolesAutocomplete roleId={roleUUID} entityId={domainId as string} />}
            </Box>
          </TabPanel>
          <TabPanel value={getCurrentTabIndex()} index={2}>
            <Box>
              <Typography mb={1} color="red">
                Danger zone
              </Typography>
              <Box
                onClick={() => {
                  deleteRole(
                    new DeleteRoleRequest({
                      roleId: roleUUID,
                    }),
                    {
                      onSuccess: () => {
                        navigation(
                          `/${
                            urlEntityType[Number(toe) as Entity_Type as keyof typeof urlEntityType]
                          }/${domainId}/manage?tab=roles`
                        );
                      },
                    }
                  );
                }}
                sx={{ cursor: 'pointer' }}
                display="flex"
                alignItems="center"
              >
                <DeleteIcon /> Delete role
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
};

export default ManageRole;
