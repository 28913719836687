import { Box, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { FieldNames } from '@pages/Marketplace/ManageDemands/settings';
import { Form } from '@components/Form';
import * as React from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { useUpdateInterviewRequirements } from '@services/queries/MarketplaceQueries';
import MDRenderer from '@components/Form/controllers/WysiwygController/MDRenderer';
import SettingsIcon from '@mui/icons-material/Settings';

type UpdateRequirementsProps = {
  demand: Demand;
};

const UpdateInterviewRequirements = (props: UpdateRequirementsProps) => {
  const { demand } = props;
  const [isEdited, setIsEdited] = useState(true);
  const requirements = (demand?.entity?.value as Interview)?.requirements;
  const { mutate: updateInterviewRequirements } = useUpdateInterviewRequirements();
  const handleSubmit = (data: any) => {
    if (isEdited) {
      setIsEdited(!isEdited);
    }
    setIsEdited(!isEdited);

    if (requirements === data.requirements) return;

    updateInterviewRequirements({
      interviewId: demand?.entity.value?.interviewId?.value as string,
      requirements: data.requirements,
    });
  };

  return demand ? (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{
        defaultValues: {
          interviewId: demand?.entity?.value?.interviewId,
          requirements,
        },
      }}
      submitHandler={handleSubmit}
    >
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Requirements</Typography>
          <Box display="flex" flexDirection="column">
            <Tooltip placement="left" title="Update requirements">
              <LoadingButton type="submit">
                {isEdited ? <SettingsIcon /> : <CheckIcon />}
              </LoadingButton>
            </Tooltip>
          </Box>
        </Box>

        {isEdited ? (
          <Box display="flex" flexDirection="column">
            {requirements && <MDRenderer content={requirements} />}
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" width="100%">
            <WysiwygController name={`${FieldNames.REQUIREMENTS}`} />
          </Box>
        )}
      </Box>
    </Form>
  ) : null;
};

export default UpdateInterviewRequirements;
