import React from 'react';
import Box from '@mui/material/Box';
import { useListUserSquads } from '@services/queries/SquadsQueries';
import { useListUserOrgs } from '@services/queries/OrgsQueries';
import { Squads } from '@proto/profiler/biz/v1/squad_pb';
import Typography from '@mui/material/Typography';
import MembershipSquadCard from '@pages/Main/MainPage/MembershipSquadCard';
import MembershipOrgCard from '@pages/Main/MainPage/MembershipOrgCard';
import { Orgs } from '@proto/profiler/biz/v1/org_pb';
import Fade from '@mui/material/Fade';

export const ListMemberships = (): JSX.Element => {
  const { data: squadsList, error: squadsError, isLoading: squadsLoading } = useListUserSquads();
  const { data: orgsList, error: orgsError, isLoading: orgsLoading } = useListUserOrgs();

  // if (squadsLoading || orgsLoading) return <CircularProgress />;

  return (
    <Fade in>
      <Box display="flex" flexDirection="column" gap={2}>
        {squadsList?.squads && (
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography className="font-bold">Squads</Typography>

            <Box display="flex" flexDirection="column" gap={1}>
              {(squadsList?.squads as Squads)?.squads?.map((squad, index) => {
                return (
                  <Box key={squad.squadId?.value} display="flex" flexDirection="column" gap={0.5}>
                    <MembershipSquadCard squad={squad} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

        {orgsList?.orgs && (
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography className="font-bold">Organizations</Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              {(orgsList?.orgs as Orgs)?.orgs?.map((org, index) => {
                return (
                  <Box key={org.orgId?.value} display="flex" flexDirection="column" gap={0.5}>
                    <MembershipOrgCard org={org} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Fade>
  );
};
export default ListMemberships;
