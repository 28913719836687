// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file notificator/stream/v1/notifications.proto (package notificator.stream.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Event_Bell } from './events_pb.js';
import { ChatMember, Groups, Message as Message$1, Messages } from './chat_pb.js';

/**
 * @generated from message notificator.stream.v1.SubscribeRequest
 */
export class SubscribeRequest extends Message<SubscribeRequest> {
  constructor(data?: PartialMessage<SubscribeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.SubscribeRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribeRequest {
    return new SubscribeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribeRequest {
    return new SubscribeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribeRequest {
    return new SubscribeRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SubscribeRequest | PlainMessage<SubscribeRequest> | undefined,
    b: SubscribeRequest | PlainMessage<SubscribeRequest> | undefined
  ): boolean {
    return proto3.util.equals(SubscribeRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.SubscribeResponse
 */
export class SubscribeResponse extends Message<SubscribeResponse> {
  /**
   * @generated from oneof notificator.stream.v1.SubscribeResponse.payload
   */
  payload:
    | {
        /**
         * @generated from field: notificator.stream.v1.Connect groups = 1;
         */
        value: Connect;
        case: 'groups';
      }
    | {
        /**
         * @generated from field: notificator.stream.v1.Event.Bell bell = 2;
         */
        value: Event_Bell;
        case: 'bell';
      }
    | {
        /**
         * @generated from field: notificator.stream.v1.Chat chat = 3;
         */
        value: Chat;
        case: 'chat';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SubscribeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.SubscribeResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'groups', kind: 'message', T: Connect, oneof: 'payload' },
    { no: 2, name: 'bell', kind: 'message', T: Event_Bell, oneof: 'payload' },
    { no: 3, name: 'chat', kind: 'message', T: Chat, oneof: 'payload' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribeResponse {
    return new SubscribeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribeResponse {
    return new SubscribeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribeResponse {
    return new SubscribeResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SubscribeResponse | PlainMessage<SubscribeResponse> | undefined,
    b: SubscribeResponse | PlainMessage<SubscribeResponse> | undefined
  ): boolean {
    return proto3.util.equals(SubscribeResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.Chat
 */
export class Chat extends Message<Chat> {
  /**
   * @generated from oneof notificator.stream.v1.Chat.payload
   */
  payload:
    | {
        /**
         * @generated from field: notificator.stream.v1.ChatMember member = 1;
         */
        value: ChatMember;
        case: 'member';
      }
    | {
        /**
         * @generated from field: notificator.stream.v1.Message message = 2;
         */
        value: Message$1;
        case: 'message';
      }
    | {
        /**
         * @generated from field: notificator.stream.v1.Messages messages = 3;
         */
        value: Messages;
        case: 'messages';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Chat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Chat';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'member', kind: 'message', T: ChatMember, oneof: 'payload' },
    { no: 2, name: 'message', kind: 'message', T: Message$1, oneof: 'payload' },
    { no: 3, name: 'messages', kind: 'message', T: Messages, oneof: 'payload' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Chat {
    return new Chat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Chat {
    return new Chat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Chat {
    return new Chat().fromJsonString(jsonString, options);
  }

  static equals(
    a: Chat | PlainMessage<Chat> | undefined,
    b: Chat | PlainMessage<Chat> | undefined
  ): boolean {
    return proto3.util.equals(Chat, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.Connect
 */
export class Connect extends Message<Connect> {
  /**
   * @generated from field: notificator.stream.v1.Groups groups = 1;
   */
  groups?: Groups;

  /**
   * group_id -> messages count
   *
   * @generated from field: map<string, int32> unread_messages = 2;
   */
  unreadMessages: { [key: string]: number } = {};

  constructor(data?: PartialMessage<Connect>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Connect';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'groups', kind: 'message', T: Groups },
    {
      no: 2,
      name: 'unread_messages',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Connect {
    return new Connect().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Connect {
    return new Connect().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Connect {
    return new Connect().fromJsonString(jsonString, options);
  }

  static equals(
    a: Connect | PlainMessage<Connect> | undefined,
    b: Connect | PlainMessage<Connect> | undefined
  ): boolean {
    return proto3.util.equals(Connect, a, b);
  }
}
