import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';
import { useListFeedItems } from '../../../services/queries/MarketplaceQueries';
import { useMarketplace } from '../../../store/marketplace';
import { sortDemandsByDate } from '../Dashboards/config';
import { Interview } from '../../../proto/marketplace/demand/v1/interview_pb';
import { Contract } from '../../../proto/marketplace/demand/v1/contract_pb';
import DemandCard from './DemandCard';

export const DateDisplay: React.FC<{ date: string; showDate: boolean }> = ({ date, showDate }) => (
  <Box
    width="10%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    {showDate && (
      <Typography fontSize="10px" color="textSecondary">
        {date}
      </Typography>
    )}
    <Box
      sx={{
        height: showDate ? '110%' : '120%',
        width: '1px',
      }}
      className="bg-white/10"
    />
  </Box>
);

const FeedList = () => {
  const { data: feedListResponse, isLoading } = useListFeedItems();
  const { displayListType } = useMarketplace();
  const contractList = Object.values(feedListResponse?.feedItems?.contracts || {});
  const interviewsList = Object.values(feedListResponse?.feedItems?.interviews || {});

  const displayOptions = React.useMemo(
    () => ({
      all: [...contractList, ...interviewsList],
      contracts: contractList,
      interviews: interviewsList,
    }),
    [contractList, interviewsList]
  );

  const memoizedDisplayList = React.useMemo(
    () => sortDemandsByDate(displayOptions[displayListType as keyof typeof displayOptions]),
    [displayOptions, displayListType]
  );
  const displayedDates = new Map<string, boolean>();

  if (isLoading && !memoizedDisplayList?.length) return <LinearProgress />;

  return (
    <Fade in>
      <Box className="flex w-full flex-col relative flex-end gap-2">
        <Box display="flex" flexDirection="column" width="100%" className="gap-1">
          {memoizedDisplayList &&
            memoizedDisplayList.map((demand, index) => {
              const date = new Date(
                demand?.entity?.value?.createdAt?.toDate() || ''
              ).toLocaleDateString();
              let showDate = false;

              if (!displayedDates.has(date) && index !== 0) {
                displayedDates.set(date, true);
                showDate = true;
              }

              return (
                <Box
                  className="flex gap-1 pl-4 w-full"
                  key={
                    (demand.entity.value as Interview).interviewId?.value ||
                    (demand.entity.value as Contract).contractId?.value
                  }
                >
                  {/* <DateDisplay date={date} showDate={showDate} /> */}
                  <Box
                    sx={{ cursor: 'pointer' }}
                    width="100%"
                    className="transition-all flex gap-1 flex-col text-[13px] "
                  >
                    {showDate && (
                      <Box className="flex justify-center text-[12px] font-bold">{date}</Box>
                    )}
                    <DemandCard demand={demand} />
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Fade>
  );
};

export default FeedList;
