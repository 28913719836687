import { create } from 'zustand/react';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';

interface NotificationsState {
  selectedNotificationsIds: UUID[] | null;
  readNotificationsIds: UUID[] | null;
  setReadNotificationsIds: (readNotificationsIds: UUID[] | null) => void;
  setSelectedNotificationsIds: (selectedNotificationsIds: UUID[] | null) => void;
  toggleSelectAll: (ids: UUID[]) => void;
  toggleSelectId: (id: UUID) => void;
}

export const useNotificationsState = create<NotificationsState>((set, get) => ({
  selectedNotificationsIds: null,
  readNotificationsIds: null,
  setSelectedNotificationsIds: (selectedNotificationsIds: UUID[] | null) =>
    set({ selectedNotificationsIds }),
  setReadNotificationsIds: (readNotificationsIds: UUID[] | null) => set({ readNotificationsIds }),
  toggleSelectAll: (ids: UUID[]) => {
    const currentIds = get().selectedNotificationsIds;
    const allSelected =
      currentIds && ids.every((id) => currentIds.some((selected) => selected.value === id.value));

    set({ selectedNotificationsIds: allSelected ? null : ids });
  },

  toggleSelectId: (id: UUID) => {
    const currentIds = get().selectedNotificationsIds || [];
    const isSelected = currentIds.some((selected) => selected.value === id.value);

    set({
      selectedNotificationsIds: isSelected
        ? currentIds.filter((selected) => selected.value !== id.value)
        : [...currentIds, id],
    });
  },
}));
