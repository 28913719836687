import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '@common/routes';
import NotificationCard from '@components/NotificationPopover/NotificationCard';
import { useStreamStore } from '../../zustand/subscribe';

const NotificationPopover = () => {
  const { bellNotifications } = useStreamStore();
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      event.target instanceof Node &&
      !popoverRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleBellClick = () => {
    if (bellNotifications?.length > 0) {
      return handleClick();
    }
    return navigate(ROUTES.NOTIFICATIONS);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  return (
    <Box position="relative">
      <Badge
        badgeContent={bellNotifications?.length}
        color="primary"
        onClick={handleBellClick}
        className="cursor-pointer"
      >
        {bellNotifications?.length === 0 ? (
          <NotificationsIcon />
        ) : (
          <NotificationsActiveIcon className="BellAnimation" />
        )}
      </Badge>
      {isOpen && (
        <Box
          ref={popoverRef}
          position="absolute"
          top="100%"
          right={0}
          width="500px"
          py={2}
          className="flex gap-2 bg-diamond flex-col shadow-lg rounded-md"
          zIndex={10}
        >
          <Typography className="px-2 font-bold text-center">Notifications</Typography>
          {bellNotifications?.length === 0 ? (
            <Typography className="px-2">You have no new notifications.</Typography>
          ) : (
            <Box className="flex gap-1 flex-col">
              {bellNotifications.map((event) => (
                <NotificationCard
                  closePopover={() => setIsOpen(false)}
                  notification={event}
                  showMarkAsRead
                />
              ))}
            </Box>
          )}

          <Link to={ROUTES.NOTIFICATIONS} className="NavLink" onClick={() => setIsOpen(false)}>
            <Typography textAlign="center" className="hover:underline">
              Show more
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default NotificationPopover;
