import Box from '@mui/material/Box';
import React from 'react';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useCreatePolicy } from '@services/queries/IAMQueries';
import { CreatePolicyRequest, RWX } from '@proto/iam/auth/v1/iam_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';

const PreAddedPolicyItem = ({
  policy,
  roleId,
}: {
  policy: { value: Entity_Type; label: string };
  roleId: string;
}) => {
  const { mutate: createPolicy } = useCreatePolicy();

  const [rwx, setRwx] = React.useState<{ r: boolean; w: boolean; x: boolean }>({
    r: false,
    w: false,
    x: false,
  });

  const handleSubmit = () => {
    if (!policy.value || (!rwx.r && !rwx.w && !rwx.x)) {
      return;
    }

    createPolicy(
      new CreatePolicyRequest({
        roleId: new UUID({ value: roleId }),
        toe: Number(policy.value),
        rwx: new RWX({
          r: rwx.r,
          w: rwx.w,
          x: rwx.x,
        }),
      }),
      {
        onSuccess: () => {
          setRwx({ r: false, w: false, x: false });
        },
      }
    );
  };

  return (
    <Box
      key={policy.label}
      className="flex items-center capitalize  justify-between group bg-[#546e7a]/10 hover:bg-[#546e7a]/30 rounded transition-all px-2 py-2"
    >
      {policy.label}

      <Box className="flex items-center gap-2">
        <Box className="flex gap-2">
          <Box display="flex" alignItems="center">
            <Typography>Read</Typography>
            <Checkbox
              checked={rwx.r}
              onChange={(e, checked) => {
                setRwx((prev) => ({
                  ...prev,
                  r: checked,
                  w: checked ? prev.w : false,
                }));
              }}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography>Write</Typography>
            <Checkbox
              checked={rwx.w}
              onChange={(e, checked) => {
                setRwx((prev) => ({
                  ...prev,
                  r: checked ? true : prev.r,
                  w: checked,
                }));
              }}
            />
          </Box>
        </Box>
        <Box
          className={`${rwx.r ? 'text-white cursor-pointer' : 'text-white/50'}`}
          onClick={handleSubmit}
        >
          Save
        </Box>
      </Box>
    </Box>
  );
};

export default PreAddedPolicyItem;
