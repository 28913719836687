import Joi from 'joi';

export enum FieldNames {
  NAME = 'name',
  READ = 'read',
  WRITE = 'write',
  EXECUTE = 'execute',
}

export const FIELD_LABELS = {
  [FieldNames.NAME]: 'Role name',
  [FieldNames.READ]: 'Read',
  [FieldNames.WRITE]: 'Write',
  [FieldNames.EXECUTE]: 'Execute',
};

export const createRolesSchema = {
  [FieldNames.NAME]: Joi.string().required().min(3).max(20).messages({
    'string.empty': 'fill in the first name',
    'string.min': 'first name must be at least 3 characters long',
    'string.max': 'first name must be less than or equal to 30 characters long',
  }),
  id: Joi.string().allow(),
};
