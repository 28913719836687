import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import ExploreOrgCard from '@pages/Orgs/ExploreOrgs/ExploreOrgCard';
import SearchOrgByTags from '@pages/Orgs/SearchOrgByTags';
import { Page } from '@components/Page/Page';
import SearchOrgByName from '@pages/Orgs/SearchOrgByName';
import { useSearchOrgs } from '@services/queries/OrgsQueries';
import { useSearchParams } from 'react-router-dom';
import { Org } from '@proto/profiler/biz/v1/org_pb';
import Container from '@mui/material/Container';
import { LoaderWithDelay } from '@components/LoaderWithDelay/LoaderWithDelay';
import Fade from '@mui/material/Fade';

const ExploreOrgsList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const { data: listOrgs, isLoading } = useSearchOrgs(selectedTags);

  const handleTagClick = (id: number) => {
    const updatedTags = selectedTags.includes(id)
      ? selectedTags.filter((tagId) => tagId !== id)
      : [...selectedTags, id];

    setSelectedTags(updatedTags);
    setSearchParams({ tags: updatedTags.join(',') });
  };

  const handleClearTags = () => {
    setSelectedTags([]);
    setSearchParams({});
  };

  useEffect(() => {
    const tagsFromQuery = searchParams.get('tags');
    if (tagsFromQuery) {
      setSelectedTags(tagsFromQuery.split(',').map(Number));
    }
  }, [searchParams]);

  if (isLoading) return <LoaderWithDelay />;

  return (
    <Page description="Explore orgs" title="Explore orgs">
      <Fade in>
        <Container>
          <Box
            className="relative flex flex-col"
            sx={{
              height: 'calc(100vh - 120px)',
            }}
          >
            {/* Header */}
            <Box className=" bg-transparent text-white flex  w-full z-10 sticky top-0" />

            <div className="flex flex-1 gap-4 overflow-hidden">
              {/* Main Content */}
              <Box className="flex-1 overflow-y-auto ">
                {listOrgs?.orgs?.orgs && (
                  <Masonry columns={{ xs: 1, sm: 2 }} spacing={2}>
                    {listOrgs?.orgs?.orgs?.map((org: Org) => (
                      <Box key={org.orgId?.value}>
                        <ExploreOrgCard handleClickTag={handleTagClick} org={org} />
                      </Box>
                    ))}
                  </Masonry>
                )}
              </Box>
              {/* Sidebar */}
              <aside className="w-80 bg-transparent flex-shrink-0 h-full sticky top-0 overflow-y-auto overflow-x-hidden">
                <Box mb={2} className=" flex  w-full">
                  <SearchOrgByName />
                </Box>
                <SearchOrgByTags
                  handleClearTags={handleClearTags}
                  handleClickTag={handleTagClick}
                />
              </aside>
            </div>
          </Box>
        </Container>
      </Fade>
    </Page>
  );
};

export default ExploreOrgsList;
