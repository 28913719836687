import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { InviteAction, InviteActionRequest } from '@proto/profiler/invite/v1/invite_pb';
import React from 'react';
import { useInviteAction, useListUserInvites } from '@services/queries/ProfilesQueries';

type TEntityInviteCardProps = {
  entityId?: string;
  successCallback?: () => void;
};

const EntityInviteCard = (props: TEntityInviteCardProps) => {
  const { entityId, successCallback } = props;
  const { data, error } = useListUserInvites();
  const { mutate } = useInviteAction();
  const handleAccept = () => {
    mutate(
      new InviteActionRequest({
        inviteId: data?.invites?.find((invite) => invite?.entity?.id?.value === entityId)?.inviteId,
        action: InviteAction.ACCEPT,
      }),
      { onSuccess: successCallback }
    );
  };

  const handleDecline = () => {
    mutate(
      new InviteActionRequest({
        inviteId: data?.invites?.find((invite) => invite?.entity?.id?.value === entityId)?.inviteId,
        action: InviteAction.REJECT,
      })
    );
  };

  if (error?.message?.includes('[not_found]')) return null;

  return data?.invites?.find((invite) => invite?.entity?.id?.value === entityId) ? (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={2} alignItems="center">
      <Typography variant="subtitle1">You were invited</Typography>
      <Box display="flex" gap={1}>
        <Button variant="outlined" color="success" onClick={handleAccept}>
          Accept
        </Button>
        <Button onClick={handleDecline} variant="outlined" color="error">
          Decline
        </Button>
      </Box>
    </Box>
  ) : null;
};

export default EntityInviteCard;
