import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { SortedEvaluations } from '@services/queries/MarketplaceQueries';
import { Typography } from '@mui/material';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';
import { Evaluation } from '@proto/marketplace/demand/v1/evaluation_pb';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';
import EvaluationCard from '@pages/Main/MainPage/Evaluations/EvaluationCard';
import { DateDisplay } from '@pages/Marketplace/Feed/FeedList';
import { sortEvaluationsByScheduledAt } from '@pages/Main/MainPage/Evaluations/config';

const SortedEvaluationsList = (props: {
  evaluations: SortedEvaluations;
  tags?: { [key: string]: Tag[] };
}) => {
  const { evaluations, tags } = props;

  const [displayListType, setDisplayListType] = useState<string>('all');
  const notScheduled = Object.values(evaluations?.notScheduled as Evaluation[]);
  const scheduled = Object.values(evaluations?.scheduled as Evaluation[]);
  const completed = Object.values(evaluations?.completed as Evaluation[]);

  const displayedDates = new Map<string, boolean>();

  const combinedList = [...notScheduled, ...scheduled, ...completed];

  let displayList;

  if (displayListType === 'all') displayList = sortEvaluationsByScheduledAt(combinedList);

  return (
    <Fade in timeout={500}>
      <Box flexDirection="column" display="flex" gap={1} position="relative" px={1}>
        <Typography className="font-bold">Evaluations</Typography>
        <Box className="flex flex-col ">
          {displayList && displayList.length
            ? displayList?.map((ev, index) => {
                const date = isInvalidTimestamp(ev?.status?.scheduledAt?.toJsonString())
                  ? new Date(ev?.status?.scheduledAt?.toDate() || '').toLocaleDateString()
                  : 'Not scheduled';

                let showDate = false;

                if (!displayedDates.has(date)) {
                  displayedDates.set(date, true);
                  showDate = true;
                }

                return (
                  <Box display="flex" className="gap-4" width="100%">
                    <EvaluationCard evaluation={ev} />
                    <DateDisplay date={date} showDate={showDate} />
                  </Box>
                );
              })
            : 'Empty list'}
        </Box>
      </Box>
    </Fade>
  );
};

export default SortedEvaluationsList;
