import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useCreateRole } from '@services/queries/IAMQueries';
import { createRolesSchema, FIELD_LABELS, FieldNames } from '@components/Roles/config';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

type CreateRoleFormProps = {
  id: string;
  isOpen: boolean;
  handleClose: () => void;
};

export const CreateRoleForm = (props: CreateRoleFormProps) => {
  const { id, isOpen, handleClose } = props;
  const { mutate: createRole } = useCreateRole();

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box width="500px" padding="40px">
        <Typography variant="subtitle1" textAlign="center" mb={2}>
          Create role
        </Typography>
        <Form
          width="100%"
          display="flex"
          alignSelf="center"
          flexDirection="column"
          formProps={{ defaultValues: { name: '', id } }}
          submitHandler={(data) => {
            createRole(data);
            handleClose();
          }}
          resolverSchema={createRolesSchema}
        >
          <Stack spacing={3} direction="column" mb={2} className="second-step">
            <InputController name={`${FieldNames.NAME}`} label={FIELD_LABELS[FieldNames.NAME]} />
          </Stack>
          <Box display="flex" justifyContent="center">
            <LoadingButton type="submit" variant="contained">
              Create role
            </LoadingButton>
          </Box>
        </Form>
      </Box>
    </Dialog>
  );
};
