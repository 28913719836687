import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DeletePolicyRequest } from '@proto/iam/auth/v1/iam_pb';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip } from '@mui/material';
import React from 'react';
import { toePolicyLabel } from '@components/Roles/ManageRole';
import { useDeletePolicy, useGetRole } from '@services/queries/IAMQueries';
import { useParams } from 'react-router-dom';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { createSelectOptions } from '@utils/createSelectOptionsFromEnum';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import PreAddedPolicyItem from '@components/Roles/PreAddedPolicyItem';

const ManagePolicies = () => {
  const { roleId, domainId, toe } = useParams();
  const roleUUID = new UUID({ value: roleId });
  const { data: role } = useGetRole(roleUUID);
  const { mutate: deletePolicy } = useDeletePolicy();

  const toeOptions = createSelectOptions(Entity_Type, [
    Entity_Type.UNSPECIFIED,
    ...(role?.policies?.map((policy) => policy.toe) || []),
  ]);

  return (
    <Box className="flex gap-6">
      <Box className="flex flex-col gap-1 w-[50%]">
        {toeOptions?.map((entity) => (
          <PreAddedPolicyItem roleId={roleId as string} policy={entity} />
        ))}
      </Box>
      <Box className="flex flex-col gap-1 w-[50%]">
        {role?.policies &&
          role?.policies.map((policy) => (
            <Box className="group h-[58px] flex items-center w-full justify-between bg-[#546e7a]/10  hover:bg-[#546e7a]/30 rounded transition-all px-2 py-2">
              <Box className="flex w-full items-center justify-between gap-2">
                <Box className="flex gap-2 items-center">
                  <Typography>
                    {toePolicyLabel[policy.toe as keyof typeof toePolicyLabel]}
                  </Typography>
                </Box>

                <Box className="flex gap-1 items-center">
                  <Box
                    onClick={() => {
                      deletePolicy(
                        new DeletePolicyRequest({
                          roleId: roleUUID,
                          toe: policy.toe,
                        })
                      );
                    }}
                    className={`opacity-0 ${
                      !role?.system && 'group-hover:opacity-100'
                    } hover:text-[red] cursor-pointer transition-all`}
                  >
                    <DeleteIcon />
                  </Box>
                  {policy?.rwx?.w ? <Chip size="small" label="Write" /> : null}
                  {policy?.rwx?.r ? <Chip size="small" label="Read" /> : null}
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default ManagePolicies;
