import { Box, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { FieldNames } from '@pages/Marketplace/ManageDemands/settings';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';
import { Form } from '@components/Form';
import * as React from 'react';
import { useState } from 'react';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import {
  useDeleteContract,
  useUpdateContractDescription,
} from '@services/queries/MarketplaceQueries';
import MDRenderer from '@components/Form/controllers/WysiwygController/MDRenderer';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';

type UpdateDemandTraitsProps = {
  demand: Demand;
};

const UpdateContractDescription = (props: UpdateDemandTraitsProps) => {
  const { demand } = props;
  const [isEdited, setIsEdited] = useState(true);
  const { mutate: updateContractDescription } = useUpdateContractDescription();
  const { mutate: deleteContract, error } = useDeleteContract();
  const description = (demand?.entity?.value as Contract)?.description;

  const handleSubmit = (data: any) => {
    console.log({ data });
    if (isEdited) {
      setIsEdited(!isEdited);
      return;
    }
    updateContractDescription(data);
    setIsEdited(!isEdited);
  };

  return demand ? (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{
        defaultValues: {
          description,
          contractId: (demand?.entity?.value as Contract).contractId,
        },
      }}
      submitHandler={handleSubmit}
    >
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Description</Typography>
          <Box display="flex" flexDirection="column">
            <Tooltip placement="left" title="Update requirements">
              <LoadingButton type="submit">
                {isEdited ? <SettingsIcon /> : <CheckIcon />}
              </LoadingButton>
            </Tooltip>
          </Box>
        </Box>

        {isEdited ? (
          <Box display="flex" flexDirection="column" gap={2}>
            {description && <MDRenderer content={description} />}
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" width="100%">
            <WysiwygController name={FieldNames.DESCRIPTION} />
            {/* <Box display="flex"> */}
            {/*  <Box> */}
            {/*    <Tooltip placement="top-start" title="Delete contract"> */}
            {/*      <LoadingButton variant="contained" onClick={handleOpen}> */}
            {/*        <DeleteForeverIcon /> */}
            {/*      </LoadingButton> */}
            {/*    </Tooltip> */}
            {/*    <ConfirmDeleteDialog */}
            {/*        setIsEdited={setIsEdited} */}
            {/*        handleClose={handleClose} */}
            {/*        isOpen={isOpen} */}
            {/*        deleteMethod={deleteContract} */}
            {/*        demandId={(demand?.entity?.value as Contract).contractId?.value as string} */}
            {/*    /> */}
            {/*  </Box> */}
            {/* </Box> */}
          </Box>
        )}
      </Box>
    </Form>
  ) : null;
};

export default UpdateContractDescription;
