import { useMarketplace } from '@store/marketplace';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useListAppliedDemands } from '@services/queries/MarketplaceQueries';
import { sortDemandsByDate } from '@pages/Marketplace/Dashboards/config';
import DemandAppliedCard from '@pages/Main/MainPage/DemandAppliedCard';
import ScrollableContainer from '@components/ScrollableContainerІ';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import FiltersFeedItems from '@pages/Marketplace/Feed/FiltersFeedItems';

const ListAppliedDemands = () => {
  const [currentStatus, setCurrentStatus] = useState('approved');
  const { displayListType } = useMarketplace();

  const { data, isLoading } = useListAppliedDemands({ refetch: true });
  const demands = data?.demands;

  const contractList = React.useMemo(
    () =>
      (demands && Object.values(demands[currentStatus as keyof typeof demands]?.contracts)) || [],
    [demands, currentStatus]
  );

  const interviewsList = React.useMemo(
    () =>
      (demands && Object.values(demands[currentStatus as keyof typeof demands]?.interviews)) || [],
    [demands, currentStatus]
  );

  const combinedList = React.useMemo(
    () => [...contractList, ...interviewsList],
    [contractList, interviewsList]
  );

  const displayOptions = React.useMemo(
    () => ({
      all: sortDemandsByDate(combinedList),
      contracts: sortDemandsByDate(contractList),
      interviews: sortDemandsByDate(interviewsList),
    }),
    [combinedList, contractList, interviewsList]
  );

  return !isLoading ? (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography className="font-bold">You applied demands</Typography>
      {demands ? (
        <Box display="flex" flexDirection="column" gap={2}>
          {displayOptions[displayListType as keyof typeof displayOptions] ? (
            <Box className="flex flex-col gap-2">
              <Box className="flex flex-col">
                <Box className="flex flex-col">
                  <Typography fontSize="12px" className="text-[silver]">
                    Filter by type
                  </Typography>
                  <FiltersFeedItems />
                </Box>

                {/* <Box className="flex flex-col"> */}
                {/*    <Typography fontSize="12px" className="text-[silver]"> */}
                {/*        Filter by status */}
                {/*    </Typography> */}
                {/*    <Box display="flex" alignItems="center" py={1} gap={2}> */}
                {/*        {Object.keys(demands).map((status) => ( */}
                {/*            <Chip */}
                {/*                onClick={() => handleFilterChange(status)} */}
                {/*                className="capitalize" */}
                {/*                variant={currentStatus === status ? 'filled' : 'outlined'} */}
                {/*                key={status} */}
                {/*                label={status} */}
                {/*            /> */}
                {/*        ))} */}
                {/*    </Box> */}
                {/* </Box> */}
              </Box>

              <ScrollableContainer height="65vh">
                <Box display="flex" flexDirection="column" pr={1} gap={1}>
                  {displayOptions[displayListType as keyof typeof displayOptions] &&
                    displayOptions[displayListType as keyof typeof displayOptions].map((demand) => {
                      return (
                        <Box
                          key={
                            (demand.entity.value as Interview).interviewId?.value ||
                            (demand.entity.value as Contract).contractId?.value
                          }
                        >
                          <DemandAppliedCard demand={demand} />
                        </Box>
                      );
                    })}
                </Box>
              </ScrollableContainer>
            </Box>
          ) : (
            <Typography>You not have applied demands.</Typography>
          )}
        </Box>
      ) : (
        <Box display="flex" gap={1}>
          <Typography>You have not yet become a supplier.</Typography>
          <Link className="NavLink" to="/feed-list">
            <Typography sx={{ textDecoration: 'underline' }}>Explore feed</Typography>
          </Link>
        </Box>
      )}
    </Box>
  ) : (
    <Box>...</Box>
  );
};

export default ListAppliedDemands;
