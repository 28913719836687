import { getConnectClient } from '@services/api/helpers';
import { BreakDownAPI } from '@proto/api/marketplace/v1/break_down_connect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AddAssigneesRequest,
  AddStepRequest,
  AddStepResponse,
  CreateBreakDownRequest,
  CreateBreakDownResponse,
  DeleteAssigneesRequest,
  DeleteBreakDownRoleRequest,
  GetStepRequest,
  GetStepResponse,
  RemoveStepRequest,
  RemoveStepResponse,
  UpdateBreakDownRequest,
  UpdateBreakDownResponse,
  UpdateBreakDownRoleRequest,
  UpdateStepRequest,
  UpdateStepResponse,
  UpdateStepsPositionsRequest,
} from '@proto/marketplace/product/v1/break_down_pb';
import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';
import { ProductOrderAPI } from '@proto/api/marketplace/v1/order_connect';
import {
  CreateProductOrderRequest,
  DeleteProductOrderRequest,
  ListProductOrdersRequest,
} from '@proto/marketplace/product/v1/order_pb';

const stepsClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  BreakDownAPI
);

const orderClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ProductOrderAPI
);

export const useCreateProductOrder = () => {
  const dispatch = useAppDispatch();
  const createProductOrder = async (payload: CreateProductOrderRequest) => {
    return orderClient.createProductOrder(payload);
  };

  return useMutation({
    mutationFn: createProductOrder,
    onSuccess: () => {
      // queryClient.invalidateQueries({queryKey: ['listProductOrders']});
      dispatch(
        showSnackbar({ id: 'create-product-order', message: 'Order created', severity: 'success' })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({ id: 'create-product-order', message: error.message, severity: 'error' })
      );
    },
  });
};

export const useListProductOrders = ({
  payload,
  enabled,
}: {
  payload: ListProductOrdersRequest;
  enabled: boolean;
}) => {
  const listProductOrders = async () => {
    return orderClient.listProductOrders(payload);
  };

  return useQuery({
    queryKey: ['listProductOrders', payload.by.value],
    queryFn: listProductOrders,
    enabled,
  });
};

export const useDeleteProductOrder = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const deleteProductOrder = async (payload: DeleteProductOrderRequest) => {
    return orderClient.deleteProductOrder(payload);
  };

  return useMutation({
    mutationFn: deleteProductOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listProductOrders'] });
      dispatch(
        showSnackbar({ id: 'delete-product-order', message: 'Order deleted', severity: 'success' })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({ id: 'delete-product-order', message: error.message, severity: 'error' })
      );
    },
  });
};

export const useCreateBreakDown = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const createBreakDown = async (
    payload: CreateBreakDownRequest
  ): Promise<CreateBreakDownResponse> => {
    return stepsClient.createBreakDown(payload);
  };

  return useMutation({
    mutationFn: createBreakDown,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(
        showSnackbar({
          id: 'create-break-down',
          message: 'Break down created',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({ id: 'create-break-down', message: error.message, severity: 'error' })
      );
    },
  });
};

export const useDeleteBreakdownRole = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const deleteBreakdownRole = async (payload: DeleteBreakDownRoleRequest) => {
    return stepsClient.deleteBreakDownRole(payload);
  };

  return useMutation({
    mutationFn: deleteBreakdownRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(
        showSnackbar({
          id: 'delete-breakdown-role',
          message: 'Break down role deleted',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({ id: 'delete-breakdown-role', message: error.message, severity: 'error' })
      );
    },
  });
};

export const useUpdateBreakdownRole = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const updateBreakDownRole = async (payload: UpdateBreakDownRoleRequest) => {
    return stepsClient.updateBreakDownRole(payload);
  };

  return useMutation({
    mutationFn: updateBreakDownRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(
        showSnackbar({
          id: 'update-break-down-role',
          message: 'Break down role updated',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({ id: 'update-break-down-role', message: error.message, severity: 'error' })
      );
    },
  });
};

export const useUpdateBreakDown = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const updateBreakDown = async (
    payload: UpdateBreakDownRequest
  ): Promise<UpdateBreakDownResponse> => {
    return stepsClient.updateBreakDown(payload);
  };

  return useMutation({
    mutationFn: updateBreakDown,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(
        showSnackbar({
          id: 'update-break-down',
          message: 'Break down updated',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({ id: 'update-break-down', message: error.message, severity: 'error' })
      );
    },
  });
};

export const useAddStep = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const addStep = async (payload: AddStepRequest): Promise<AddStepResponse> => {
    return stepsClient.addStep(payload);
  };

  return useMutation({
    mutationFn: addStep,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(showSnackbar({ id: 'add-step', message: 'Step added', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'add-step', message: error.message, severity: 'error' }));
    },
  });
};

export const useRemoveStep = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const removeStep = async (payload: RemoveStepRequest): Promise<RemoveStepResponse> => {
    return stepsClient.removeStep(payload);
  };

  return useMutation({
    mutationFn: removeStep,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(showSnackbar({ id: 'remove-step', message: 'Step removed', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'remove-step', message: error.message, severity: 'error' }));
    },
  });
};

export const useGetStep = () => {
  const getStep = async (payload: GetStepRequest): Promise<GetStepResponse> => {
    return stepsClient.getStep(payload);
  };

  return useMutation({
    mutationFn: getStep,
    // onSuccess: () => {
    //     queryClient.invalidateQueries({queryKey: ['listSteps']});
    //     dispatch(
    //         showSnackbar({id: 'get-step', message: 'Step fetched', severity: 'success'})
    //     );
    // },
  });
};

export const useUpdateStep = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const updateStep = async (payload: UpdateStepRequest): Promise<UpdateStepResponse> => {
    return stepsClient.updateStep(payload);
  };

  return useMutation({
    mutationFn: updateStep,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(showSnackbar({ id: 'update-step', message: 'Step updated', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'update-step', message: error.message, severity: 'error' }));
    },
  });
};

export const useUpdateStepsPositions = () => {
  const queryClient = useQueryClient();
  const updateStepsPositions = async (payload: UpdateStepsPositionsRequest) => {
    return stepsClient.updateStepsPositions(payload);
  };

  return useMutation({
    mutationFn: updateStepsPositions,
    // onSuccess: () => {
    //     queryClient.invalidateQueries({queryKey: ['getProduct']});
    // }
  });
};

export const useAddAssignees = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const addAssignees = async (payload: AddAssigneesRequest) => {
    return stepsClient.addAssignees(payload);
  };

  return useMutation({
    mutationFn: addAssignees,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(
        showSnackbar({ id: 'add-assignees', message: 'Assignees added', severity: 'success' })
      );
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'add-assignees', message: error.message, severity: 'error' }));
    },
  });
};

export const useDeleteAssignees = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const deleteAssignees = async (payload: DeleteAssigneesRequest) => {
    return stepsClient.deleteAssignees(payload);
  };

  return useMutation({
    mutationFn: deleteAssignees,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      dispatch(
        showSnackbar({ id: 'delete-assignees', message: 'Assignees deleted', severity: 'success' })
      );
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'delete-assignees', message: error.message, severity: 'error' }));
    },
  });
};
