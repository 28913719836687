// Popover.tsx
import React, { useCallback, useEffect, useRef } from 'react';

interface PopoverProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Popover: React.FC<PopoverProps> = ({ isOpen, onClose, children, style, className }) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (popoverRef.current && event.target instanceof Node) {
        const path = event.composedPath() as Node[];

        const triggerClicked = path.some((node) => {
          return node instanceof HTMLElement && node.hasAttribute('data-popover-trigger');
        });

        if (triggerClicked) {
          return;
        }

        if (!popoverRef.current.contains(event.target)) {
          onClose();
        }
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside, true);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [isOpen, handleClickOutside]);

  if (!isOpen) return null;

  return (
    <div ref={popoverRef} style={style} className={className}>
      {children}
    </div>
  );
};

export default Popover;
