import { useListProductOrders } from '@services/queries/StepsQueries';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { ListProductOrdersRequest } from '@proto/marketplace/product/v1/order_pb';

const ListOrders = () => {
  const { data: userProfile } = useGetMyProfile();
  const { data: listOrders } = useListProductOrders({
    enabled: !!userProfile?.profile?.profileId,
    payload: new ListProductOrdersRequest({
      by: {
        case: 'customerId',
        value: userProfile?.profile?.profileId as UUID,
      },
    }),
  });
  console.log({ listOrders });
  return <div>ListOrders</div>;
};

export default ListOrders;
