import { Page } from '@components/Page/Page';
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDeleteEvent, useListEvents, useMarkAsRead } from '@services/queries/Chat';
import NotificationCard from '@components/NotificationPopover/NotificationCard';
import { Timestamp } from '@bufbuild/protobuf';
import Checkbox from '@mui/material/Checkbox';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { ListResponse_Event } from '@proto/notificator/stream/v1/events_pb';
import { toast } from 'sonner';
import { useNotificationsState } from '../../zustand/notifications';
import { useStreamStore } from '../../zustand/subscribe';

function mapEventsToReadStatus(events: ListResponse_Event[]): Record<string, boolean> {
  return events.reduce((acc, event) => {
    if (!event.bell || !event.bell.payload || !event.bell.payload.id) {
      return acc;
    }

    const eventId = event.bell.payload.id.value;
    acc[eventId] = Boolean(event.read);
    return acc;
  }, {} as Record<string, boolean>);
}

function filterUnreadNotifications(
  mapEvent: Record<string, boolean>,
  selectedNotificationsIds: { value: string }[]
): { value: string }[] | null {
  const filtered = selectedNotificationsIds.filter((notification) => !mapEvent[notification.value]);

  if (filtered.length === 0 && selectedNotificationsIds.length > 0) {
    return null;
  }

  return filtered;
}

export const Notifications = () => {
  const { data: bellNotifications } = useListEvents();
  const { selectedNotificationsIds, toggleSelectAll, setSelectedNotificationsIds } =
    useNotificationsState();
  const { clearBellNotifications } = useStreamStore();

  const { mutate: deleteEvent } = useDeleteEvent();
  const { mutate: markAsRead } = useMarkAsRead();

  return (
    <Page description="Orgs" title="Orgs">
      <Container>
        <Box className="px-5 flex gap-1 items-center">
          {bellNotifications?.events?.length ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    !!selectedNotificationsIds &&
                    selectedNotificationsIds.length === bellNotifications?.events?.length
                  }
                  onChange={() =>
                    toggleSelectAll(
                      bellNotifications?.events?.map(
                        (notification) => notification?.bell?.payload?.id as UUID
                      )
                    )
                  }
                />
              }
              label="Select all"
            />
          ) : null}
          {selectedNotificationsIds?.length ? (
            <Box className="flex items-center gap-4">
              <Tooltip className="cursor-pointer" title="Mark as read selected" placement="top">
                <Box
                  className=" w-7 h-7 flex items-center justify-center bg-white/20 hover:bg-white/40 transition-all border-white/80  border rounded-full "
                  onClick={() => {
                    const filteredNotificationsIds = filterUnreadNotifications(
                      mapEventsToReadStatus(bellNotifications?.events || []),
                      selectedNotificationsIds
                    );
                    if (!filteredNotificationsIds) {
                      return toast.error('All notifications are already read');
                    }
                    return markAsRead(
                      new UUIDS({ values: selectedNotificationsIds.map((id) => id.value) }),
                      {
                        onSuccess: () => {
                          clearBellNotifications();
                          setSelectedNotificationsIds(null);
                        },
                      }
                    );
                  }}
                >
                  <Box className="cursor-pointer w-2.5 h-2.5 bg-white/80 border-white/80 border  rounded-full" />
                </Box>
              </Tooltip>
              <Tooltip
                onClick={() => {
                  deleteEvent(
                    new UUIDS({ values: selectedNotificationsIds.map((id) => id.value) }),
                    { onSuccess: () => setSelectedNotificationsIds(null) }
                  );
                }}
                className="cursor-pointer"
                title="Delete selected"
                placement="top"
              >
                <DeleteIcon className="hover:text-[#ab003c] transition-all" />
              </Tooltip>
            </Box>
          ) : null}
        </Box>
        {bellNotifications?.events?.length === 0 ? (
          <Typography>You have no notifications.</Typography>
        ) : (
          <Box className="flex  flex-col">
            {bellNotifications?.events?.map((event) => {
              return event?.bell ? (
                <NotificationCard
                  read={event?.read || new Timestamp()}
                  notification={event?.bell}
                  showSettings
                />
              ) : null;
            })}
          </Box>
        )}
      </Container>
    </Page>
  );
};
