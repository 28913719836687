import { getConnectClient } from '@services/api/helpers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';
import {
  AddUserToRoleResponse,
  CreateDelegationsResponse,
  CreatePolicyRequest,
  CreatePolicyResponse,
  CreateRoleResponse,
  DeleteDelegationsResponse,
  DeletePolicyRequest,
  DeletePolicyResponse,
  DeleteRoleRequest,
  DeleteRoleResponse,
  DeleteUserFromRoleRequest,
  DeleteUserFromRoleResponse,
  GetRoleRequest,
  GetRoleResponse,
  ListDelegationsRequest,
  ListDelegationsResponse,
  ListRolesRequest,
  ListRolesResponse,
  ListUserPermissionsRequest,
  ListUserPermissionsResponse,
} from '@proto/iam/auth/v1/iam_pb';
import {
  AddUserToRoleRequest,
  CreateDelegationsRequest,
  CreateRoleRequest,
  DeleteDelegationsRequest,
} from '@proto/api/iam/v1/iam_pb';

import { IAMAPI } from '@proto/api/iam/v1/iam_connect';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';

const iAMClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  IAMAPI
);

export const useCreateRole = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const createRole = async (payload: { name: string; id: string }): Promise<CreateRoleResponse> => {
    return iAMClient.createRole(
      new CreateRoleRequest({
        name: payload.name,
        domainId: new UUID({ value: payload.id }),
      })
    );
  };

  return useMutation({
    mutationFn: createRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listEntityRoles'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Role created', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};

export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const deleteRole = async (payload: DeleteRoleRequest): Promise<DeleteRoleResponse> => {
    return iAMClient.deleteRole(payload);
  };

  return useMutation({
    mutationFn: deleteRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listEntityRoles'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Role deleted', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};

export const useListRoles = (payload: ListRolesRequest) => {
  const getRole = async (): Promise<ListRolesResponse> => {
    return iAMClient.listRoles(payload);
  };

  return useQuery({
    queryKey: ['listEntityRoles', payload],
    queryFn: getRole,
    enabled: !!payload.domainId?.value,
  });
};

export const useListUserPermissions = (payload: ListUserPermissionsRequest) => {
  const getPermissions = async (): Promise<ListUserPermissionsResponse> => {
    return iAMClient.listUserPermissions(payload);
  };

  return useQuery({
    queryKey: ['listRoles', payload],
    queryFn: getPermissions,
    enabled: !!payload.domainId?.value,
  });
};

export const useCreatePolicy = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const createPolicy = async (payload: CreatePolicyRequest): Promise<CreatePolicyResponse> => {
    return iAMClient.createPolicy(payload);
  };

  return useMutation({
    mutationFn: createPolicy,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRole'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Policy created', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};

export const useDeletePolicy = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const deletePolicy = async (payload: DeletePolicyRequest): Promise<DeletePolicyResponse> => {
    return iAMClient.deletePolicy(payload);
  };

  return useMutation({
    mutationFn: deletePolicy,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRole'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Policy deleted', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};

export const useAddUserToRole = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const attachRole = async (payload: AddUserToRoleRequest): Promise<AddUserToRoleResponse> => {
    return iAMClient.addUserToRole(payload);
  };

  return useMutation({
    mutationFn: attachRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRole'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Role attached', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};

export const useDeleteUserFromRole = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const detachRole = async (
    payload: DeleteUserFromRoleRequest
  ): Promise<DeleteUserFromRoleResponse> => {
    return iAMClient.deleteUserFromRole(payload);
  };

  return useMutation({
    mutationFn: detachRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRole'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Role detached', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};

export const useGetRole = (payload: UUID | undefined) => {
  const queryClient = useQueryClient();

  const getRole = async (): Promise<GetRoleResponse> => {
    return iAMClient.getRole(new GetRoleRequest({ roleId: payload }));
  };

  return useQuery({
    queryFn: getRole,
    queryKey: ['getRole', payload],
    enabled: !!payload,
  });
};

export const useListDelegations = (payload: ListDelegationsRequest) => {
  const getDelegations = async (): Promise<ListDelegationsResponse> => {
    return iAMClient.listDelegations(payload);
  };

  return useQuery({
    queryKey: ['listDelegations'],
    queryFn: getDelegations,
  });
};

export const useCreateDelegation = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const createDelegation = async (
    payload: CreateDelegationsRequest
  ): Promise<CreateDelegationsResponse> => {
    return iAMClient.createDelegations(payload);
  };

  return useMutation({
    mutationFn: createDelegation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listDelegations'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Delegation created', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};

export const useDeleteDelegation = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const deleteDelegation = async (
    payload: DeleteDelegationsRequest
  ): Promise<DeleteDelegationsResponse> => {
    return iAMClient.deleteDelegations(payload);
  };

  return useMutation({
    mutationFn: deleteDelegation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listDelegations'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Delegation deleted', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};
