import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Box, Chip, Typography } from '@mui/material';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import * as React from 'react';

const ManageDemandHeader = ({ demand }: { demand: Demand | undefined }) => {
  return demand ? (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box className="flex gap-4 items-center ">
        <Typography variant="subtitle2">{demand?.entity.case?.toUpperCase()}</Typography>
        <Box>
          {demand?.entity.value?.tags?.tags
            .filter((tag) => tag.tagType === TagType.ROLE)
            .map((tag) => {
              return (
                <Chip
                  size="small"
                  key={tag.tagId}
                  variant="outlined"
                  className="!font-bold"
                  label={tag.value}
                />
              );
            })}
        </Box>
      </Box>
    </Box>
  ) : null;
};
export default ManageDemandHeader;
