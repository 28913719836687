import Box from '@mui/material/Box';

type CardProps = {
  children: React.ReactNode;
  border?: string;
};
const Card = (props: CardProps) => {
  const { children, border } = props;
  return (
    <Box
      position="relative"
      className="rounded-xl  p-4  w-full border-white/10 bg-white/5  transition"
    >
      {children}
    </Box>
  );
};
export default Card;
