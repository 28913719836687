// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file profiler/user/v1/pin.proto (package profiler.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { Entity, Entity_Type } from '../../../grpc/type/v1/entity_pb.js';

/**
 * @generated from message profiler.user.v1.PinnedItem
 */
export class PinnedItem extends Message<PinnedItem> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from field: google.protobuf.Timestamp pinned_at = 2;
   */
  pinnedAt?: Timestamp;

  constructor(data?: PartialMessage<PinnedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.PinnedItem';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    { no: 2, name: 'pinned_at', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PinnedItem {
    return new PinnedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PinnedItem {
    return new PinnedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PinnedItem {
    return new PinnedItem().fromJsonString(jsonString, options);
  }

  static equals(
    a: PinnedItem | PlainMessage<PinnedItem> | undefined,
    b: PinnedItem | PlainMessage<PinnedItem> | undefined
  ): boolean {
    return proto3.util.equals(PinnedItem, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.PinItemRequest
 */
export class PinItemRequest extends Message<PinItemRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<PinItemRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.PinItemRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PinItemRequest {
    return new PinItemRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PinItemRequest {
    return new PinItemRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PinItemRequest {
    return new PinItemRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: PinItemRequest | PlainMessage<PinItemRequest> | undefined,
    b: PinItemRequest | PlainMessage<PinItemRequest> | undefined
  ): boolean {
    return proto3.util.equals(PinItemRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.PinItemResponse
 */
export class PinItemResponse extends Message<PinItemResponse> {
  constructor(data?: PartialMessage<PinItemResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.PinItemResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PinItemResponse {
    return new PinItemResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PinItemResponse {
    return new PinItemResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PinItemResponse {
    return new PinItemResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: PinItemResponse | PlainMessage<PinItemResponse> | undefined,
    b: PinItemResponse | PlainMessage<PinItemResponse> | undefined
  ): boolean {
    return proto3.util.equals(PinItemResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UnpinItemRequest
 */
export class UnpinItemRequest extends Message<UnpinItemRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<UnpinItemRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UnpinItemRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnpinItemRequest {
    return new UnpinItemRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnpinItemRequest {
    return new UnpinItemRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnpinItemRequest {
    return new UnpinItemRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UnpinItemRequest | PlainMessage<UnpinItemRequest> | undefined,
    b: UnpinItemRequest | PlainMessage<UnpinItemRequest> | undefined
  ): boolean {
    return proto3.util.equals(UnpinItemRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UnpinItemResponse
 */
export class UnpinItemResponse extends Message<UnpinItemResponse> {
  constructor(data?: PartialMessage<UnpinItemResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UnpinItemResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnpinItemResponse {
    return new UnpinItemResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnpinItemResponse {
    return new UnpinItemResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnpinItemResponse {
    return new UnpinItemResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UnpinItemResponse | PlainMessage<UnpinItemResponse> | undefined,
    b: UnpinItemResponse | PlainMessage<UnpinItemResponse> | undefined
  ): boolean {
    return proto3.util.equals(UnpinItemResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UnpinItemForEveryoneRequest
 */
export class UnpinItemForEveryoneRequest extends Message<UnpinItemForEveryoneRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<UnpinItemForEveryoneRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UnpinItemForEveryoneRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UnpinItemForEveryoneRequest {
    return new UnpinItemForEveryoneRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UnpinItemForEveryoneRequest {
    return new UnpinItemForEveryoneRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UnpinItemForEveryoneRequest {
    return new UnpinItemForEveryoneRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UnpinItemForEveryoneRequest | PlainMessage<UnpinItemForEveryoneRequest> | undefined,
    b: UnpinItemForEveryoneRequest | PlainMessage<UnpinItemForEveryoneRequest> | undefined
  ): boolean {
    return proto3.util.equals(UnpinItemForEveryoneRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UnpinItemForEveryoneResponse
 */
export class UnpinItemForEveryoneResponse extends Message<UnpinItemForEveryoneResponse> {
  constructor(data?: PartialMessage<UnpinItemForEveryoneResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UnpinItemForEveryoneResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UnpinItemForEveryoneResponse {
    return new UnpinItemForEveryoneResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UnpinItemForEveryoneResponse {
    return new UnpinItemForEveryoneResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UnpinItemForEveryoneResponse {
    return new UnpinItemForEveryoneResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UnpinItemForEveryoneResponse | PlainMessage<UnpinItemForEveryoneResponse> | undefined,
    b: UnpinItemForEveryoneResponse | PlainMessage<UnpinItemForEveryoneResponse> | undefined
  ): boolean {
    return proto3.util.equals(UnpinItemForEveryoneResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListPinnedItemsRequest
 */
export class ListPinnedItemsRequest extends Message<ListPinnedItemsRequest> {
  /**
   * @generated from oneof profiler.user.v1.ListPinnedItemsRequest.by
   */
  by:
    | {
        /**
         * @generated from field: grpc.type.v1.Entity.Type toe = 1;
         */
        value: Entity_Type;
        case: 'toe';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListPinnedItemsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListPinnedItemsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'toe', kind: 'enum', T: proto3.getEnumType(Entity_Type), oneof: 'by' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListPinnedItemsRequest {
    return new ListPinnedItemsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListPinnedItemsRequest {
    return new ListPinnedItemsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListPinnedItemsRequest {
    return new ListPinnedItemsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListPinnedItemsRequest | PlainMessage<ListPinnedItemsRequest> | undefined,
    b: ListPinnedItemsRequest | PlainMessage<ListPinnedItemsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListPinnedItemsRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListPinnedItemsResponse
 */
export class ListPinnedItemsResponse extends Message<ListPinnedItemsResponse> {
  /**
   * @generated from field: repeated profiler.user.v1.PinnedItem pinned_items = 1;
   */
  pinnedItems: PinnedItem[] = [];

  constructor(data?: PartialMessage<ListPinnedItemsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListPinnedItemsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pinned_items', kind: 'message', T: PinnedItem, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListPinnedItemsResponse {
    return new ListPinnedItemsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListPinnedItemsResponse {
    return new ListPinnedItemsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListPinnedItemsResponse {
    return new ListPinnedItemsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListPinnedItemsResponse | PlainMessage<ListPinnedItemsResponse> | undefined,
    b: ListPinnedItemsResponse | PlainMessage<ListPinnedItemsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListPinnedItemsResponse, a, b);
  }
}
