// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/iam/v1/iam.proto (package api.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AddUserToRoleRequest,
  CreateDelegationsRequest,
  CreateRoleRequest,
  DeleteDelegationsRequest,
} from './iam_pb.js';
import {
  AddUserToRoleResponse,
  CreateDelegationsResponse,
  CreatePolicyRequest,
  CreatePolicyResponse,
  CreateRoleResponse,
  DeleteDelegationsResponse,
  DeletePolicyRequest,
  DeletePolicyResponse,
  DeleteRoleRequest,
  DeleteRoleResponse,
  DeleteUserFromRoleRequest,
  DeleteUserFromRoleResponse,
  GetRoleRequest,
  GetRoleResponse,
  ListDelegationsRequest,
  ListDelegationsResponse,
  ListRolesRequest,
  ListRolesResponse,
  ListUserPermissionsRequest,
  ListUserPermissionsResponse,
} from '../../../iam/auth/v1/iam_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.iam.v1.IAMAPI
 */
export const IAMAPI = {
  typeName: 'api.iam.v1.IAMAPI',
  methods: {
    /**
     * @generated from rpc api.iam.v1.IAMAPI.CreateRole
     */
    createRole: {
      name: 'CreateRole',
      I: CreateRoleRequest,
      O: CreateRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.DeleteRole
     */
    deleteRole: {
      name: 'DeleteRole',
      I: DeleteRoleRequest,
      O: DeleteRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.AddUserToRole
     */
    addUserToRole: {
      name: 'AddUserToRole',
      I: AddUserToRoleRequest,
      O: AddUserToRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.DeleteUserFromRole
     */
    deleteUserFromRole: {
      name: 'DeleteUserFromRole',
      I: DeleteUserFromRoleRequest,
      O: DeleteUserFromRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.CreatePolicy
     */
    createPolicy: {
      name: 'CreatePolicy',
      I: CreatePolicyRequest,
      O: CreatePolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.DeletePolicy
     */
    deletePolicy: {
      name: 'DeletePolicy',
      I: DeletePolicyRequest,
      O: DeletePolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.ListRoles
     */
    listRoles: {
      name: 'ListRoles',
      I: ListRolesRequest,
      O: ListRolesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.GetRole
     */
    getRole: {
      name: 'GetRole',
      I: GetRoleRequest,
      O: GetRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.ListUserPermissions
     */
    listUserPermissions: {
      name: 'ListUserPermissions',
      I: ListUserPermissionsRequest,
      O: ListUserPermissionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.ListDelegations
     */
    listDelegations: {
      name: 'ListDelegations',
      I: ListDelegationsRequest,
      O: ListDelegationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.CreateDelegations
     */
    createDelegations: {
      name: 'CreateDelegations',
      I: CreateDelegationsRequest,
      O: CreateDelegationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.IAMAPI.DeleteDelegations
     */
    deleteDelegations: {
      name: 'DeleteDelegations',
      I: DeleteDelegationsRequest,
      O: DeleteDelegationsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
